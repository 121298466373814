import { PAY_TYPE } from './type';

export const PayTypePriceMapper: Readonly<Record<Partial<PAY_TYPE>, number>> = {
  USE_SPACE_WITH_CAFE: 2000,
  USE_SPACE_ONLY: 2000,
  ADDITIONAL_PAY: 0,
  BREAD: 2000,
  CHANGE_SEAT: 0,
};

export const PayType2Dto: Readonly<Record<Partial<PAY_TYPE>, string>> = {
  USE_SPACE_ONLY: 'SPACE_ONLY',
  USE_SPACE_WITH_CAFE: 'WITH_CAFE',
  ADDITIONAL_PAY: 'ADDITIONAL',
  BREAD: 'BREAD',
  CHANGE_SEAT: '',
};

export const Dto2PayType: Readonly<Record<string, PAY_TYPE>> = {
  SPACE_ONLY: 'USE_SPACE_ONLY',
  WITH_CAFE: 'USE_SPACE_WITH_CAFE',
  ADDITIONAL: 'ADDITIONAL_PAY',
};
