import { PLACE_NAME } from 'utils/const';

export const en = {
  translation: {
    카공24: 'Cagong24',
    카공24타이틀: `Where you can \nstudy all night \n"${PLACE_NAME}"\nJust 2,000₩`,
    지금예약: 'Book now',
    하루공부하기: 'Study at a 24-hour cafe 2,000₩ per day',
    카공24소개:
      'Cagong 24 affiliates can freely \nuse the cafe space at any time, \nwithout time restrictions, \neven after operating hours.',
    답답한스터디카페: 'Stuffy study cafe,',
    카페에서공부하자: 'Let’s study in a real cafe!',
    실제카페에서공부: 'At Cagong 24, you can study during evening hours at an actual cafe.',

    // eslint-disable-next-line quotes
    뭐가좋은가요: "What's good?",
    카공24의장점: 'Advantages of Cagong 24',
    카페공부24시간: 'Study at a \n24-hour cafe',
    밤에도카페공부: 'Study comfortably at the cafe\n even at night \nwhen the cafe is not open.',
    저렴한비용: 'Low cost',
    스터디카페가격비교: 'Study Cafe 15,000₩ per day \n VS.\n Cagong24 2,000₩ per day',
    눈치안보고카공: 'Study at cafe \nwithout walking \non eggshells',
    눈차안보고카공하세요: '',
    시간마다커피그만: 'Stop buying a coffee every three hours.',
    눈치보지말고편하게: 'Be comfortable',
    편하게설명: 'There is no time limit\nand no obligation to buy a drink per person.',
    밤새공부하러가기: 'Study all night',
    카공24실이용자들의: 'from real users of Cagong24',
    이용후기: 'Reviews',

    이용예약: 'Reservation',
    좌석을선택해주세요: 'Please select seats',
    좌석을먼저선택해주세요: 'Please select seats first!',
    날짜선택: 'Reservation date',
    번좌석: ' seats',

    결제하기: 'Purchase',
    결제: 'Purchase',
    일시: 'Date',
    위치: 'Where',
    좌석: 'Seat(s)',
    금액: 'Amount',
    새벽: 'AM',

    // eslint-disable-next-line quotes
    카페마감이후에가요: "Cafe closes (00:30)\nI'll go later! 2,000₩",
    카페마감이전에가요: 'Cafe closes (00:30)\nI’ll be there before! 2,000₩',
    카페마감이전설명:
      'After purchasing drinks for the number of seats, you will need to show the receipt to the manager, so please bring it with you!',
    결제하실정보를확인: 'Please check your payment information',

    일: 'Sun',
    월: 'Mon',
    화: 'Tue',
    수: 'Wed',
    목: 'Thu',
    금: 'Fri',
    토: 'Sat',

    원: '₩',
    마이페이지: 'My Page',
    저장하기: 'Save',
    카공24구매후멘트: 'Thank you for using Cagong24 😃',
    이용안내: 'Receipt & Notice',

    마이페이지타이틀: 'My Page',
    안녕하세요: 'Hello :)',
    추가결제안내:
      'If you choose to come and use the cafe before it closes, but come after it closes, an additional payment of 3,000 ₩ is required. If necessary, please select a white seat and pay additionally.',
    카페마감이후이용권: 'After cafe closing\n2,000₩',
    카페마감이전이용권: 'Before cafe closing\n2,000₩',

    원결제하기: 'Purchase {{price}}₩',
    번좌석결제하기: 'Purchase {{seatNumbers}} seats',

    인증번호를먼저발송: 'Please send the authentication number first.',
    서비스이용을위한인증: 'Authentication is required once to use the service.',
    개인정보동의:
      'When submitting the authentication number, you are deemed to agree to the Terms of Use and Privacy Policy.',
    인증번호전송: 'Send Code',
    인증하기: 'Authenticate',
    전화번호를인증: 'Please authenticate using your phone number',
    토큰만료됨: 'The session is expired so that login is required. Please login!',

    전화번호를확인: 'Please check your phone number is valid.',
    인증번호발송완료: 'Code for authenticate is sent.',
    전화번호를입력: 'Please enter your phone number',

    인증번호를입력: 'Please enter your authentication number',
    재전송: 'Resend',
    남은시간: 'Remaining Time',

    빵구매: 'Buy fresh\nbreads',
    빵구매타이틀: 'Buy fresh breads',
    빵구매설명: 'Bakery baked today\nRegardless of the type',
    빵구매안내:
      '1. Please check if there is any bread left before purchasing.\n2. Please be conscientious and take only the quantity you purchased!\n3. Purchases are available here after 00:00.',
    한개이상구매: 'You must choose one or more breads!',
    빵구매가능시간아님: 'This is not the time to purchase bread here.\nAvailable hours: 00:00 to 07:00',

    자리변경: 'Change Seat',
    여기로옮기기: 'Change Seat',
    취소: 'Cancel',
    자리옮기기: 'Change Seat',
    좌석개수매칭안됨: 'The number of seats to be moved and the current number of seats to be changed are different.',
    좌석선택이안됐어요: 'Seats are not selected.',
    자리변경성공: 'Changing seats is succeed!',
  },
};
