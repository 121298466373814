import { PLACE_NAME } from 'utils/const';

export const ko = {
  translation: {
    카공24: '카공24',
    카공24타이틀: `밤새 카공할 곳, \n"${PLACE_NAME}"\n하루종일 2,000원`,
    지금예약: '지금 예약하기',
    하루공부하기: '하루 2,000원 공부하기',
    카공24소개: '카공24 가맹점은 운영시간 이후에도\n언제나, 시간제한 없이 카페공간을\n자유롭게 이용할 수 있어요.',
    답답한스터디카페: '답답한 스터디카페,',
    카페에서공부하자: '진짜 카페에서 공부하자!',
    실제카페에서공부: '카공24에서는 실제로 운영하는 카페에서\n저녁시간동안 카공을 할 수 있어요',
    뭐가좋은가요: '뭐가 좋은가요?',
    카공24의장점: '카공24만의 장점',
    카페공부24시간: '24시간 카페에서 공부',
    밤에도카페공부: '카페가 운영하지 않는 밤에도\n카페에서 편안하게 공부하세요',
    저렴한비용: '저렴한 비용',
    스터디카페가격비교: '스터디 카페 하루 15,000원 \n VS.\n카공 하루 2,000원',
    눈치안보고카공: '눈치보지 않고 카공',
    눈차안보고카공하세요: '카페 사장님 눈치,\n더 이상 보지말고 카공하세요.',
    시간마다커피그만: '3시간마다 커피 한잔은 그만,',
    눈치보지말고편하게: '눈치보지 말고 편하게!',
    편하게설명: '시간 제한도, 1인 1음료 의무도 X\n하루종일 있어도, 노트북을 꺼내써도,\n사장님 눈치보지 말고 편하게',
    밤새공부하러가기: '밤새 공부하러 가기',
    카공24실이용자들의: '카공24 실이용자들의',
    이용후기: '이용후기',

    이용예약: '이용예약',
    좌석을선택해주세요: '좌석을 선택해주세요',
    좌석을먼저선택해주세요: '좌석을 먼저 선택해주세요!',
    날짜선택: '날짜선택',
    번좌석: '번 좌석',
    원: '원',

    결제하기: '결제하기',
    결제: '결제',
    카페마감이후에가요: '카페마감 (00:30)\n이후에 갈거에요! 2,000원',
    카페마감이전에가요: '카페마감 (00:30)\n이전에 갈거에요! 2,000원',
    카페마감이전설명: '좌석 개수만큼 음료를 구매하신 후, 매니저에게 영수증을 보여주셔야하니 지참해주세요!',
    결제하실정보를확인: '결제하실 정보를 확인해주세요',

    새벽: '새벽',
    마이페이지: '마이페이지',
    저장하기: '저장하기',
    카공24구매후멘트: '카공24를 이용해주셔서 감사합니다 😃',
    이용안내: '이용안내',

    마이페이지타이틀: 'My 예약확인 & 추가결제',
    안녕하세요: '안녕하세요',
    추가결제안내:
      '카페마감 이전에 와서 이용하는 것으로 선택하셨다가, 마감 이후에 오신 경우에는, 3천원 추가결제가 필요합니다. 필요한 경우, 흰색 좌석을 선택하여 추가결제 부탁드립니다.',
    카페마감이후이용권: '카페마감 이후\n2,000원권',
    카페마감이전이용권: '카페마감 이전\n2,000원권',

    원결제하기: '{{price}}원 결제하기',
    번좌석결제하기: '{{seatNumbers}}번 좌석 결제하기',

    인증번호를먼저발송: '인증번호를 먼저 발송해주세요',
    서비스이용을위한인증: '서비스 이용을 위해 인증이 한 번 필요해요.',
    개인정보동의: '인증번호 제출시 이용약관 및 개인정보 보호정책에 동의하는것으로 간주합니다.',

    인증번호전송: '인증번호 전송',
    인증하기: '인증하기',
    전화번호를인증: '전화번호를 인증해주세요',

    전화번호를확인: '전화번호를 확인해주세요!',
    인증번호발송완료: '인증번호를 발송했어요',
    전화번호를입력: '전화번호를 입력해주세요',
    토큰만료됨: '만료되어 재로그인이 필요해요',

    인증번호를입력: '인증번호를 입력해주세요',
    재전송: '재전송',
    남은시간: '남은시간',

    빵구매: '오늘구운빵\n구매하기',
    빵구매타이틀: '빵 이용하기',
    빵구매설명: '오늘 구운 베이커리\n종류상관없이',
    빵구매안내:
      '1. 빵이 남아있는지 확인하고 구매해주세요.\n2. 구매한 개수만큼만 양심있게 가져가주세요!\n3. 앱으로는 00시 이후부터 구매가능합니다.',
    한개이상구매: '0개의 빵을 구매할 수 없습니다. 하나 이상 선택해주세요',
    빵구매가능시간아님: '앱으로 빵을 구매할 수 있는 시간이 아닙니다.\n가능시간 00시~07시',

    자리변경: '자리변경',
    여기로옮기기: '여기로 옮기기',
    취소: '취소',
    자리옮기기: '자리옮기기',
    좌석개수매칭안됨: '옮길 좌석 개수와, 바꿀 현재 좌석 개수가 달라요.',
    좌석선택이안됐어요: '좌석선택이 안됐어요.',
    자리변경성공: '자리를 성공적으로 옮겼어요!',
  },
};
